import { GlobalServiceLocale, ServiceLocale } from '@tkl-packages/constants/locale';
import { isGlobal, isPc } from '../validations';
import { getGlobalChannel } from './channel';

/**
 * 글로벌 타겟과 국내의 경우 false를 가집니다.
 * - Global: string
 * - false: ko
 * @example '/global/en', 'global/ja', false
 */
type GlobalPath = string | false;

/**
 * 현재 사이트 환경(국내/해외 두 벌의 사이트)에서 사용자의 뷰 타입에 따른 관리를 도와주는 헬퍼 클래스입니다.
 * @note - 분리 확장이 아닌, 적은 양의 분기 수정을 하는 경우에 사용합니다.
 * - 트레이드오프: 너무 많이 사용하면, 코드 내 분기문이 많아지며, 너무 적게 사용하면, 중복 내용이 너무 많아집니다.
 */
export class GlobalView {
  private static GLOBAL_PATH = '/global/';
  /**
   * 절대경로에 globalPath를 적용합니다.
   * - 상대 경로는 처리하지 않습니다.
   */
  public static applyPath(absPath: string) {
    const globalPath = GlobalView.extractGlobalPath();
    return globalPath ? globalPath + absPath : absPath;
  }

  /**
   * BE 응답 중, 필드가 Kor과 Eng로 된 경우에 적용해줍니다.
   * - 글로벌 사이트: engField 출력. 비어있거나 길이가 0이면 korField 출력, 비어있거나 길이가 0이면 defaultField 출력.
   * - 국문 사이트: korField 출력, 비어있거나 길이가 0이면 defaultField 출력.
   */
  public static applyGlobalEngField(engField?: string, korField?: string, defaultField = '') {
    const fixKorField = typeof korField === 'string' && korField.length > 0 ? korField : defaultField;
    if (!GlobalView.isGlobal()) return fixKorField;
    if (typeof engField !== 'string') return fixKorField;
    if (engField.length === 0) return fixKorField;
    return engField;
  }
  /**
   * CDN 응답 중, 필드가 멀티인 경우, locale에 따른 이름을 적용합니다.
   * - 현재 locale 데이터가 없는 경우, 글로벌은 영어, 한글 순서로 default로 사용하고, 국내는 한글을 default로 사용한다.
   */
  public static applyGlobalField(record: Partial<Record<ServiceLocale, string>>) {
    const locale = GlobalView.getGlobalLocale();
    const defaultField = GlobalView.isGlobal() ? record['en'] : record['ko'];
    return record[locale || 'ko'] ?? defaultField ?? record['ko'] ?? '';
  }

  /**
   * 글로벌 사이트 여부를 반환합니다.
   */
  public static isGlobal() {
    return isGlobal();
  }
  /**
   * GlobalLocale 정보를 반환합니다.
   * @returns false는 국문 페이지를 나타냅니다.
   */
  public static getGlobalLocale() {
    const ch = getGlobalChannel();
    if (ch === 'ko') return false;
    return ch;
  }

  /**
   * 현재 path에서 global locale만 변경한 path를 반환합니다.
   * - 현재 path는 `window.location.pathname` 사용 중
   */
  public static getChangedGlobalLocalePath(globalLocale: GlobalServiceLocale) {
    return GlobalView.GLOBAL_PATH + globalLocale + GlobalView.stripGlobalPath(window.location.pathname);
  }

  /**
   * 전달받은 경로에서 global path를 제거한 경로를 반환합니다.
   * global path가 포함되지 않은 경우 해당 경로를 반환합니다.
   */
  public static stripGlobalPath(path: string): string {
    if (isGlobal()) {
      const strippedPath = path.split('/').slice(3).join('/');
      return `/${strippedPath}`;
    }

    return path;
  }

  private static extractGlobalPath(): GlobalPath | false {
    if (!isGlobal()) return false;

    return GlobalView.GLOBAL_PATH + GlobalView.getGlobalLocale();
  }

  /**
   * React에서 동적으로 입력되는 JSP 경로 관리를 위함.
   * - 정적 JSP 경로는 TKLink의 useNative 옵션을 사용하면 처리 가능하지만, 동적 JSP 경로는 추가 처리가 필요함.
   * - 해당 함수는 URL 관리 포인트를 늘리지 않기 위해서, 개발이 필요한 부분에 대해서만 관리함.
   */
  public static isPCWebServerUrl(url: string) {
    // - 모바일 `/search`는 React이기 때문에, pc와 mobile 분기해서 처리 필요.
    if (isPc()) {
      const PC_WEB_SERVER_PATH = ['/searchRenewal', '/search'] as const;
      /**
       * @note `/searchRenewal`이 `/search`로 바뀌는 경우, 단순 includes 로직 보다, 추가 조건으로 정교하게 처리가 필요할 수 있음.
       * - 현재는 아래 두가지 케이스
       * 1. https://www.ticketlink.co.kr/global/en/searchRenewal?query=SQUID+GAME
       * /searchRenewal?query=SQUID+GAME
       */
      return PC_WEB_SERVER_PATH.some((pcWebPath) => url.includes(pcWebPath));
    }
    return false;
  }

  /**
   * JSP PCWEB Server의 Controller 경로를 대체합니다.
   * - PCWEB Server 프로젝트 path 관리 목적.
   * - Renewal Path가 사라지는 경우, 제거 가능합니다.
   * - Renewal Path는 만들었지만, 기존 URL 페이지가 나가는 경우가 발생한다면, 임시 주석 처리 (예, 스마트티켓 때문에 myRenewal 펜딩 예정 있음.)
   * - 완전히 다른 기능이라면, 관리할 필요는 없습니다.
   */
  public static replacePCWebServerPath(originPath: string) {
    if (!isGlobal()) return originPath;

    const urlMap = [
      ['/search', '/searchRenewal'],
      ['/my/fanclub/list', '/myRenewal/fanclub/list'],
      ['/my/reserve/list', '/myRenewal/reserve/list'],
      ['/my/memberInfo/withdrawal', '/myRenewal/memberInfo/withdrawal'],
      ['/help/notice', '/helpRenewal/notice'],
      ['/help/main', '/helpRenewal/main'],
      ['/auth/join', '/join'],
      ['/auth/login', '/login'],
      ['/auth/logout', '/logout']
    ];
    for (const [origin, renewal] of urlMap) {
      if (originPath.includes(origin)) return originPath.replace(origin, renewal);
    }
    return originPath;
  }
}
